@import '~theme/variables';

.triggerButton {
  border-radius: 9999px;
  background-color: $color-neutral-06;
  color: $color-neutral-02;
  border: none;
  font-size: $body-font-size;
  padding: $space-s;
  padding-left: $space-m;
  padding-right: 2rem;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 1;
}

.triggerIcon {
  width: 2.4rem;
  height: 2.4rem;
}
