@import '~theme/variables';

.loader {
  display: inline-block;
  position: relative;
  fill: $color-primary;

  &.secondary {
    fill: $color-neutral-01;
  }
}

.item {
  animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.first {
  animation-delay: 0s;
}

.second {
  animation-delay: 0.12s;
}

.third {
  animation-delay: 0.24s;
}

@keyframes loader {
  0% {
    height: 3.2rem;
    transform: translate3d(0, -0.6rem, 0);
  }

  50%,
  100% {
    transform: translate3d(0, 0rem, 0);
    height: 1.6rem;
  }
}
