@import '~theme/variables';

.card {
  &:has(input:checked),
  &:focus-within {
    & > .cardFooter {
      background: $color-soft-yellow;
    }
  }
}
