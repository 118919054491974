@import '~theme/variables';

.card {
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: left;
  border-radius: $border-radius-03;
  border: 1px solid $color-neutral-04;
  background-color: $color-neutral-06;

  &:hover {
    border-color: $color-neutral-03;
  }

  &:has(input:checked),
  &:focus-within {
    border-color: $color-primary;
    box-shadow: 0 0 0 1px $color-primary, $box-shadow-01;

    & > .usps {
      background: $color-soft-yellow;
    }
  }
}
