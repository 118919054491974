@import "~theme/variables";

.container {
  display: flex;
  align-items: center;

  &.withMargin {
    margin-top: $space-xs;
  }
}

.icon {
  fill: $color-error;
  width: 2rem;
  height: 2rem;
  margin-right: 0.8rem;
  vertical-align: text-top;
}
