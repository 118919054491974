@import '~theme/variables';

@layer components {
  .h1 {
    font-size: $h1-font-size;
    line-height: $h1-line-height;
    font-weight: $h1-font-weight;
    color: $h1-color;

    @include breakpoint(md) {
      font-size: $h1-font-size-large;
    }
  }

  .h2 {
    font-size: $h2-font-size;
    line-height: $h2-line-height;
    font-weight: $h2-font-weight;
    color: $h2-color;

    @include breakpoint(md) {
      font-size: $h2-font-size-large;
      &.forceMobileView {
        font-size: $h2-font-size;
      }
    }
  }

  .h3 {
    font-size: $h3-font-size;
    line-height: $h3-line-height;
    font-weight: $h3-font-weight;
    color: $h3-color;

    @include breakpoint(md) {
      font-size: $h3-font-size-large;

      &.forceMobileView {
        font-size: $h3-font-size;
      }
    }
  }

  .h4 {
    font-size: $h4-font-size;
    line-height: $h4-line-height;
    font-weight: $h4-font-weight;
    color: $h4-color;

    @include breakpoint(md) {
      font-size: $h4-font-size-large;

      &.forceMobileView {
        font-size: $h4-font-size;
      }
    }
  }

  .h5 {
    font-size: $h5-font-size;
    line-height: $h5-line-height;
    font-weight: $h5-font-weight;
    color: $h5-color;

    @include breakpoint(md) {
      font-size: $h5-font-size-large;
    }
  }

  .price {
    font-size: $price-font-size;
    line-height: $price-line-height;
    font-weight: $price-font-weight;
    color: $price-color;

    @include breakpoint(md) {
      font-size: calc(#{$price-font-size} * 1.2);
    }
  }

  .button {
    font-size: $button-font-size;
    line-height: $button-line-height;
    font-weight: $button-font-weight;
    color: $button-color;
    display: flex;
    align-items: center;
  }

  .link {
    font-size: $link-font-size;
    line-height: $link-line-height;
    font-weight: $link-font-weight;
    color: $link-color;
  }

  .large {
    font-size: $large-font-size;
    line-height: $large-line-height;
    font-weight: $large-font-weight;
    color: $large-color;
  }

  .body {
    font-size: $body-font-size;
    line-height: $body-line-height;
    font-weight: $body-font-weight;
    color: $body-color;

    & em {
      font-style: normal;
      color: #e0001a;
    }
  }

  .small {
    font-size: $small-font-size;
    line-height: $small-line-height;
    font-weight: $small-font-weight;
    color: $small-color;
  }

  .note {
    font-size: $note-font-size;
    line-height: $note-line-height;
    font-weight: $note-font-weight;
    color: $note-color;
  }

  .section-heading {
    font-size: $section-heading-font-size;
    line-height: $section-heading-line-height;
    font-weight: $section-heading-font-weight;
    color: $section-heading-color;
  }

  .club-page-heading {
    font-family: $club-heading-font-family;
    font-size: $club-heading-font-size;
    line-height: $club-heading-line-height;
    font-weight: $club-heading-font-weight;
    letter-spacing: -0.015em;
    color: $club-heading-color;
    text-transform: uppercase;

    @include breakpoint(lg) {
      font-size: $club-heading-font-size-large;
    }
  }

  .specialHeading {
    position: static;
    font-family: $club-heading-font-family;
    font-size: $club-heading-font-size;
    line-height: $club-heading-line-height;
    font-weight: $club-heading-font-weight;
    letter-spacing: -0.015em;
    color: $club-heading-color;
    text-transform: uppercase;

    @include breakpoint(lg) {
      font-size: 7.9rem;
    }

    &::after {
      content: attr(data-content) ' ' attr(data-content) ' ' attr(data-content)
        ' ' attr(data-content);
      position: absolute;
      color: $color-neutral-05;
      font-family: $font-family-display;
      font-size: 17.6rem;
      line-height: 149.6px;
      left: 0;
      right: 0;
      overflow: hidden;
      z-index: -1;
      white-space: nowrap;
      text-align: center;
      transform: translatey(-73px);

      @include breakpoint(lg, max) {
        transform: translatey(-80px);
      }
    }
  }

  .club-page-section-heading {
    font-family: $club-heading-font-family;
    line-height: $club-heading-line-height;
    font-weight: $club-heading-font-weight;
    color: $club-heading-color;
    letter-spacing: -0.015em;
    text-transform: uppercase;

    font-size: $club-section-heading-size;

    &.small {
      font-size: $club-section-heading-size-small;
    }

    @include breakpoint(lg) {
      &:not(.forceMobileView) {
        font-size: $club-section-heading-size-large;

        &.small {
          font-size: $club-section-heading-size;
        }
      }
    }
  }

  .utility {
    color: $color-utility;
  }

  .neutral06 {
    color: $color-neutral-05;
  }

  .neutral05 {
    color: $color-neutral-05;
  }

  .neutral04 {
    color: $color-neutral-06;
  }

  .neutral03 {
    color: $color-neutral-03;
  }

  .neutral02 {
    color: $color-neutral-02;
  }

  .neutral01 {
    color: $color-neutral-01;
  }

  .primary {
    color: $color-primary;
  }

  .normal {
    font-weight: 400;
  }

  .medium {
    font-weight: 500;
  }

  .bold {
    font-weight: 600;
  }

  .error {
    color: $color-error;

    svg {
      fill: $color-error;
    }
  }

  .success {
    color: $color-success;
  }

  .line-through {
    text-decoration: line-through;
  }

  .border {
    position: relative;
    padding-top: 1.2rem;
  }

  .centered {
    @include breakpoint(sm) {
      width: 100%;
      text-align: center;

      &.border {
        &:after {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .word-break {
    word-break: break-word;
  }

  .stretchedTitle {
    z-index: -1;
    position: absolute;
    display: flex;
    gap: $space-xl;
    font-size: 27cqi;
    white-space: nowrap;
    opacity: 0.1;
    pointer-events: none;
    left: -100%;

    @include breakpoint(md, max) {
      gap: $space-m;
    }

    @include breakpoint(sm, max) {
      display: none;
    }
  }
}
