@import '~theme/variables';

.mobileContainer {
  display: block;

  @include breakpoint(sm) {
    display: none;
  }
}

.desktopContainer {
  display: none;

  @include breakpoint(sm) {
    display: block;
  }
}
