@import '~theme/variables';

.usp {
  display: flex;
  gap: 1.4rem;
}

.icon {
  fill: $color-primary;
  margin-top: 0.4rem;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
}

.grey {
  fill: $color-neutral-03;
}
