@import '~theme/variables';

.pageTitle {
  position: relative;
  font-family: $font-family-display;
  font-size: 7.9rem;
  letter-spacing: -0.015em;
  line-height: 6.7rem;
  color: $color-neutral-06;

  @include breakpoint(sm, max) {
    font-size: 6rem;
    line-height: 4.8rem;
  }
  &.dark {
    color: $color-neutral-01;
  }
}

.mobileTitle {
  @include breakpoint(sm) {
    display: none;
  }
}

.desktopTitle {
  @include breakpoint(sm, max) {
    display: none;
  }
}
