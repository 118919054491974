@import '~theme/variables';

@layer components {
  .link {
    font-size: $link-font-size;
    line-height: $link-line-height;

    font-weight: $link-font-weight;
    color: $link-color;
    transition: color $transition-01;

    svg {
      fill: currentColor;
    }

    &.small {
      font-weight: $body-font-weight;
      text-decoration: underline;

      &:hover {
        color: $body-color;
        text-decoration: none;
      }
    }

    &:hover {
      color: $color-primary;
    }
  }

  .withIcon {
    display: inline-flex;
    align-items: center;
    gap: $space-xs;
  }
}
