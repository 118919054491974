@import '~theme/variables';
@import '../../../VideoPageHeader.module.scss';

.lightTheme {
  .links {
    a,
    span {
      color: $color-neutral-01;

      &:hover {
        color: $color-primary;
      }
    }
  }
}

.body {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.inputContainer {
  position: relative;
  width: 100%;

  .searchButton {
    flex-shrink: 0;
    width: 5.4rem;
    height: 5.4rem;
    border-radius: 999px;

    svg {
      font-size: 2.4rem;
    }
  }
}

.input {
  background-color: $color-neutral-06;
  border-radius: 4.8rem;
  padding: 2.4rem 7.2rem 2.4rem 3.6rem;
  flex-grow: 1;
  border: none;
  width: 100%;

  caret-color: $color-primary;
  font-family: inherit;
  line-height: 2.8rem;
  font-size: inherit;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $color-neutral-02;
  }
}

.input-container {
  flex-grow: 1;
  margin-right: 0.8rem;
}

.searchButtonContainer {
  position: absolute;
  right: 1.2rem;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 0 $space-s;
  margin-top: $space-s;
  width: 100%;
  padding: 0 $space-m;

  div {
    text-align: center;
    margin-right: 2.5rem;
  }

  span,
  a {
    color: $color-neutral-06;
    font-size: 1.6rem;
    line-height: 2.5rem;
    font-weight: 400;
  }
  a {
    text-decoration: underline;
  }

  @include breakpoint(sm) {
    flex-direction: row;
  }
}

.locationButton {
  text-decoration: underline;
  span {
    transition: color $transition-01;
  }

  &:hover {
    span {
      color: $color-primary;
    }
  }
}
