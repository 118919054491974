@import '~theme/variables';

.container {
  font-size: $body-font-size;
  margin-bottom: $space-s;
  line-height: $body-line-height;
  font-weight: $body-font-weight;
  color: $color-neutral-01;
  white-space: pre-wrap;

  @include breakpoint(md) {
    font-size: $large-font-size;
  }

  p {
    color: $color-neutral-01;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.8rem;
  }

  h2 {
    font-size: $h2-font-size;
    line-height: $h2-line-height;
    font-weight: $h2-font-weight;
    color: $h2-color;

    @include breakpoint(md) {
      font-size: $h2-font-size-large;
    }
  }

  h3 {
    font-size: $h3-font-size;
    line-height: $h3-line-height;
    font-weight: $h3-font-weight;
    color: $h3-color;

    @include breakpoint(md) {
      font-size: $h3-font-size-large;
    }
  }

  h4 {
    font-size: $h4-font-size;
    line-height: $h4-line-height;
    font-weight: $h4-font-weight;
    color: $h4-color;

    @include breakpoint(md) {
      font-size: $h4-font-size-large;
    }
  }

  h5 {
    font-size: $h5-font-size;
    line-height: $h5-line-height;
    font-weight: $h5-font-weight;
    color: $h5-color;

    @include breakpoint(md) {
      font-size: $h5-font-size-large;
    }
  }

  h6 {
    font-size: $note-font-size;
    line-height: $note-line-height;
    font-weight: $note-font-weight;
    color: $color-neutral-02;
  }

  a {
    text-decoration: underline;
    color: $color-neutral-01;
    font-size: $body-font-size;
    font-weight: $club-heading-font-weight;

    @include breakpoint(md) {
      font-size: $large-font-size;
    }

    &:hover {
      color: $color-primary;
    }
  }

  b {
    font-weight: 600;
    color: $color-neutral-01;
  }

  ul,
  ol {
    margin-left: 2rem;
    color: $color-neutral-01;
  }

  ul {
    list-style: disc;
  }

  ul li::marker {
    color: $color-primary;
  }

  ol {
    list-style: decimal;
  }

  ol li::marker {
    font-weight: 700;
    color: $color-primary;
  }

  li {
    padding-bottom: 1.6rem;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-bottom: solid 0.1rem $color-neutral-05;
  }

  /* stylelint-disable selector-combinator-space-after */
  p +,
  ul +,
  ol +,
  hr + {
    /* stylelint-enable */
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol,
    hr {
      margin-top: 2.4rem;
    }
  }

  li p + p {
    /* stylelint-disable-line selector-max-compound-selectors */
    margin-top: 0;
  }
}

.container.light {
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  b,
  ul,
  ol {
    color: $color-neutral-06;
  }
}
