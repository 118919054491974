@import "~theme/variables";

.section {
  margin-bottom: 0;
  margin-top: $space-xxl;

  @include breakpoint(sm, max) {
    margin-top: 17rem;
  }
}

.inviteeName {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -1rem;
    left: 0;
    transform: translate(-50%, -50%);
    width: 100%;
    transform: scale(1.5);
    min-width: 8rem;
    height: 1.8rem;
    z-index: -1;
    background-image: url('/static/images/sportcity/illustrations/underline.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.termsAndConditions {
  margin-top: $space-l;

  a {
    text-decoration: underline;
    color: $color-neutral-02;

    &:hover {
      color: $color-neutral-02;
    }
  }
}