@import '~theme/variables';

.card {
  background-color: $color-neutral-05;
  padding: $space-s;
  border-radius: $space-xs;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.editButton {
  span {
    font-weight: 400;
  }
}

.row {
  margin-top: 1rem;
}
