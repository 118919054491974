@import '~theme/variables';

.badge {
  display: inline-block;
  border-radius: $border-radius-01;
  padding: 0 0.4rem;
  color: $color-neutral-01;
  background: $color-neutral-05;
}

.secondary {
  background: $color-neutral-06;
}

.pointed {
  position: relative;
  background: $badge-active-background-color;
  border-radius: $border-radius-01;
  margin-right: 1.4rem;
  max-width: 7.2rem;
  line-height: 1.1;
  text-align: left;
  display: none;

  @include breakpoint(sm) {
    margin-right: 1.6rem;
    line-height: 1.3;
    max-width: 100%;
    text-align: right;
    display: block;
  }

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    margin-left: -0.2rem;
    border-style: solid;
    border-width: 1rem 0 1.1rem 1rem;
    border-color: transparent transparent transparent
      $badge-active-background-color;
  }

  span {
    line-height: 1.1;
  }
}

.tertiary {
  background: $color-primary;

  &.text-white span {
    color: $color-neutral-06;
  }
}
