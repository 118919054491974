@import '~theme/variables';

.card {
  position: relative;
  padding: $space-l;
  background: $color-neutral-06;
  border-radius: $border-radius-04;
  border: 3px solid $color-neutral-01;
  display: flex;
  flex-direction: column;
  gap: $space-m;
  transition: box-shadow $transition-01, transform $transition-01;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  height: 100%;
  box-shadow: $box-shadow-01;

  @include breakpoint(md) {
    &:hover {
      transform: translateY(-0.2rem);
      box-shadow: $box-shadow-hover;
    }
  }

  &.primary {
    border-color: $color-primary;
  }
}

.usps {
  display: flex;
  flex-direction: column;
  gap: $space-s;
  flex: 1;
}

.priceContainer {
  display: flex;
  gap: $space-xs;
  align-items: end;
}

.badge {
  position: absolute;
  top: 0;
  right: 2rem;
  transform: translateY(-50%);
  background: $color-primary;
  color: $color-neutral-01;
  padding: 0 $space-s;
  line-height: 0.9;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 99999px;
  font-family: $font-family-display;
  font-size: 2.4rem;
  white-space: wrap;
  border: 3px solid $color-neutral-01;
  box-shadow: 0 0 0 4px $color-primary;
  transition: transform $transition-01;

  & > span {
    transform: translateY(-2px);
  }
}
