@import '~theme/variables';

.list-item {
  margin: 0.4rem 0;
}

.usp {
  position: relative;
  padding-left: 1.2rem;
  color: $color-neutral-01;
  display: flex;
  gap: $space-xs;
  align-items: center;
}

.icon {
  float: left;
  fill: $color-primary;
  margin-top: 0.4rem;
}

.tag:first-child {
  margin-right: 0.8rem;
  margin-bottom: 0.6rem;
}

.tag {
  display: inline-block;
  border-radius: $border-radius-01;
  padding: 0 0.4rem;
  color: $color-neutral-01;
  background: $color-neutral-05;

  .usp {
    padding-left: 0;

    p {
      font-size: 1.3rem;
    }
  }

  .icon {
    display: none;
  }
}

.uspBody {
  line-height: 2.6rem;
}

.grey {
  fill: $color-neutral-03;
}
