@import '~theme/variables';

.imageCollage {
  margin-bottom: 0;
  @include breakpoint(sm, max) {
    position: absolute;
    top: 0rem;
    left: 0;
    height: 50rem;
    width: 100vw;
    overflow: hidden;
    margin-top: 0;
    margin-bottom: $space-l;
  }

  @include breakpoint(sm) {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }

  @include breakpoint(lg) {
    margin-bottom: 0;
  }
}

.imageContent {
  position: relative;

  img {
    border: 0.1rem solid $color-neutral-06;
    border-radius: $border-radius-03;
    position: absolute;
    box-shadow: $box-shadow-01;
    border: 0.1rem solid $color-neutral-06;
    object-fit: cover;
  }
  @include breakpoint(sm) {
    height: 60%;
    width: 100%;
  }
}

.imgPositioner {
  position: absolute;
}

.image {
  border: 0.1rem solid $color-neutral-06;
  box-shadow: $box-shadow-01;
  border-radius: $border-radius-03;
  object-fit: cover;
}

$column-1: -1rem;
$column-2: 9rem;
$column-3: 20.6rem;
$column-4: 32.2rem;

.image1 {
  right: 50%;
  position: absolute;

  @include breakpoint(sm, max) {
    width: 10rem;
    height: 13rem;
    top: 3rem;
    margin-top: $space-s;
    left: $column-1;
  }

  @include breakpoint(sm) {
    height: 8rem;
    width: 8rem;
    top: 0rem;
  }

  @include breakpoint(md) {
    top: 0rem;
    width: 14.4rem;
    height: 14.4rem;
  }
}

.image2 {
  left: 50%;

  margin-left: $space-s;
  @include breakpoint(sm, max) {
    width: 10rem;
    height: 13rem;
    top: 3rem;
    margin-top: $space-s;
    left: $column-2;
  }

  @include breakpoint(sm) {
    width: 13rem;
    height: 11rem;
    top: -3rem;
    margin-left: 1.2rem;
  }

  @include breakpoint(md) {
    margin-left: $space-s;
    display: block;
    top: -6.3rem;
    width: 23.2rem;
    height: 20.8rem;
  }
}

.image3 {
  right: 50%;
  margin-top: $space-s;
  @include breakpoint(sm, max) {
    width: 10rem;
    height: 13rem;
    top: 1rem;
    margin-left: $space-s;
    left: $column-3;
  }

  @include breakpoint(sm) {
    width: 13rem;
    height: 11rem;
    top: 8rem;
    margin-top: 1.2rem;
  }

  @include breakpoint(md) {
    right: 50%;
    width: 24rem;
    height: 20.8rem;
    top: 14.4rem;
  }
}

.image4 {
  margin-top: $space-s;
  width: 16rem;
  height: 16rem;
  left: 50%;
  margin-left: $space-s;

  @include breakpoint(sm, max) {
    width: 10rem;
    height: 13rem;
    top: 3rem;
    left: $column-4;
    margin-left: $space-s;
  }

  @include breakpoint(sm) {
    width: 9rem;
    height: 9rem;
    top: 8rem;
    margin-top: 1.2rem;
    margin-left: 1.2rem;
  }

  @include breakpoint(md) {
    top: 14.5rem;
    margin-top: $space-s;
    margin-left: $space-s;
    width: 16rem;
    height: 16rem;
    display: block;
  }
}

.image5 {
  left: 50%;
  margin-top: $space-s;
  margin-left: $space-s;
  display: block;
  @include breakpoint(sm, max) {
    display: none;
  }

  @include breakpoint(sm) {
    width: 5rem;
    height: 5rem;
    top: 18.2rem;
    margin-top: 1.2rem;
    margin-left: 1.2rem;
  }

  @include breakpoint(md) {
    margin-top: $space-s;
    margin-left: $space-s;
    top: 32rem;
    width: 7rem;
    height: 7rem;
  }
}

.topImage {
  display: none;
  @include breakpoint(sm, max) {
    display: block;
    position: absolute;
    width: 10rem;
    height: 13rem;
  }
}

.top-image-1 {
  @include breakpoint(sm, max) {
    top: -10rem;
    left: $column-1;
  }
}

.top-image-2 {
  @include breakpoint(sm, max) {
    top: -10rem;
    left: $column-2;
    margin-left: $space-s;
  }
}

.top-image-3 {
  @include breakpoint(sm, max) {
    top: -12.1rem;
    left: $column-3;
    margin-left: $space-s;
  }
}

.top-image-4 {
  @include breakpoint(sm, max) {
    top: -10rem;
    left: $column-4;
    margin-left: $space-s;
  }
}
