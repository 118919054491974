@import '~theme/variables';

.cardBody {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  padding: 1.6rem;
  font-size: $h4-font-size;
  font-weight: 500;
  position: relative;

  @include breakpoint('sm') {
    padding: 1.6rem 2.4rem;
    font-size: $h4-font-size-large;
  }
}

.labelContainer {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  flex: 1;

  @include breakpoint('sm') {
    gap: 1.6rem;
  }
}

.tag {
  @include breakpoint('sm', 'max') {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
  }
}
