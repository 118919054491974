@import '~theme/variables';

.highlightedText {
  color: $color-neutral-01;
  position: relative;
  white-space: pre;
  word-break: break-word;

  & svg {
    position: absolute;
    z-index: -1;
    right: -2%;
    bottom: 0.5rem;
    height: 7rem;
    width: 104%;

    @include breakpoint(sm, max) {
      height: 5.5rem;
    }
  }
}
