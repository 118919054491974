@import "~theme/variables";

.pageTitle {
  font-family: $font-family-display;
  font-size: 4.8rem;
  letter-spacing: -0.015em;
  line-height: 85%;
  color: $color-neutral-01;
  word-break: break-word;

  @include breakpoint(md) {
    font-size: 6.9rem;
  }

  @include breakpoint(lg) {
    font-size: 7.9rem;
  }
}

.light {
  color: $color-neutral-06;
}

.highlightedText {
  color: $color-primary;
}