@import '~theme/variables';

.table {
  width: 100%;
  border-spacing: 0;
  border: 0.1rem solid $color-neutral-04;
  border-radius: $border-radius-03;
  margin-top: $space-m;
  table-layout: fixed;

  &.alternative {
    tr:last-child td:first-child {
      border-bottom-left-radius: $border-radius-03;
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: $border-radius-03;
    }
  }
}

.checkMarkIcon {
  fill: $color-success;
  width: 2.4rem;
  height: 2.4rem;
}

.xMarkIcon {
  fill: $color-neutral-04;
  width: 2.4rem;
  height: 2.4rem;
}
