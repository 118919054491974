@import '~theme/variables';

.card {
  border-radius: $border-radius-03;
  background-color: $color-neutral-06;
  padding: $space-s;
  cursor: pointer;
  margin-bottom: $space-xs;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: $color-tertiary;
  }
}

.titleRow {
  margin-bottom: $space-xs;
}

.field {
  select {
    padding: 1rem;
    background-color: $color-neutral-06;
    font-weight: 400;
    line-height: 30px;
  }
}

.inputField {
  padding: 1rem;
  background-color: $color-neutral-06;
  font-weight: 400;
  line-height: 30px;
}

.col {
  display: flex;
  align-items: center;

  .marker {
    fill: $color-primary;
  }

  svg {
    font-size: 1.8rem;
    margin-right: $space-xxs;
  }
}
