@import '~theme/variables';

@layer components {
  .button {
    display: inline-flex;
    padding: 1.7rem 2.4rem;
    border-radius: $border-radius-03;
    transition: color $transition-01, background-color $transition-01;
    gap: 0.8rem;

    & svg {
      transition: fill $transition-01;
      fill: currentcolor;
    }

    &.fullWidth {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &:disabled {
      background-color: $color-quaternary;
      color: $color-neutral-03;
      border: none;
    }

    &.small {
      padding: 1.6rem;
    }
  }

  .primary {
    background: $color-primary;
    color: $color-neutral-01;
    transition: color $transition-01, border-color $transition-01,
      background $transition-01;
    -webkit-tap-highlight-color: transparent;
    border: 0.2rem solid $color-primary;

    &:hover {
      background: $color-neutral-01;
      color: $color-neutral-06;
      border-color: $color-neutral-01;
    }

    &.text-white {
      color: $color-neutral-06;
    }
  }

  .primaryInverted {
    background: $color-primary;
    color: $color-neutral-01;
    -webkit-tap-highlight-color: transparent;
    transition: color $transition-01, border-color $transition-01,
      background $transition-01;
    border: 0.2rem solid $color-neutral-01;

    &:hover {
      background: $color-neutral-01;
      color: $color-neutral-06;
      border-color: $color-neutral-06;
    }

    &.text-white {
      color: $color-neutral-06;
    }
  }

  .secondary {
    background: $color-neutral-01;
    color: $color-neutral-06;
    border: 0.2rem solid $color-neutral-01;

    &:hover {
      color: $color-neutral-01;
      background-color: $color-neutral-06;
    }
  }

  .secondaryInverted {
    background: $color-neutral-01;
    color: $color-primary;
    border: 0.2rem solid $color-primary;
    transition: color $transition-01, border-color $transition-01;

    &:hover {
      color: $color-neutral-06;
      border-color: $color-neutral-01;
    }
  }

  .inverted {
    background: $color-neutral-06;
    color: $color-neutral-01;
    border: 0.2rem solid $color-neutral-01;
    padding: 1.7rem 2.4rem;

    &:hover {
      background: $color-neutral-01;
      color: $color-neutral-06;
    }

    &.small {
      padding: 1.4rem;
    }
  }

  .ghost {
    background: transparent;
    color: $color-neutral-01;

    &:hover {
      background: $color-neutral-01;
      color: $color-neutral-06;
    }
  }

  .link {
    padding: 0;
    cursor: pointer;
    text-decoration: underline;
    color: $color-neutral-01;
    transition: $transition-01;

    &:hover {
      color: $color-primary;
    }

    &.small {
      padding: 0;
    }
  }

  .club {
    background-color: $color-neutral-06;
    color: $color-neutral-01;
    border: 0.1rem solid $color-neutral-01;
    border-radius: $border-radius-02;
    padding: 0.7rem 3.2rem;
    font-weight: $body-font-weight;
    font-size: $body-font-size;

    &:hover {
      color: $color-neutral-06;
      background-color: $color-neutral-01;
    }

    &.hasIcon {
      padding: 0.8rem;
      border: none;
      display: flex;
      align-items: center;

      & svg {
        width: 1.8rem;
        height: 1.4rem;
        fill: currentcolor;
        transition: fill $transition-01;
      }

      &:hover {
        & svg {
          fill: currentcolor;
        }
      }
    }
  }

  .fullWidth {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
