@import '~theme/variables';

.table-row {
  position: relative;
}

.table-row td {
  border-top: 0.1rem solid $color-neutral-04;
  background-color: $color-neutral-05;
  padding: 1.6rem 1.2rem;
  font-weight: 700;
  gap: $space-xs;
  align-items: center;

  &:not(:first-of-type) {
    text-align: center;
  }

  &:last-of-type {
    border-left: 0.1rem solid $color-neutral-04;
  }
}

.table-row-title {
  display: flex;
  gap: $space-xxs;
}
