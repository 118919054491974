@import '~theme/variables';

.container {
  display: inline-flex;
  cursor: pointer;
}

.input {
  overflow: hidden;
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.radio-button {
  position: relative;
  display: block;
  flex-shrink: 0;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background-color: $color-neutral-06;
  border: 0.2rem solid $color-neutral-04;

  @include breakpoint(sm) {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.input.error + .radio-button {
  border-color: $color-error;
}

.input:checked,
.input:focus {
  + .radio-button {
    background-color: $color-neutral-06;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1rem;
      height: 1rem;
      background-color: $color-neutral-01;
      border-radius: 50%;
    }
  }
}
