@import '~theme/variables';

.title {
  margin-right: 5rem;
}

.body {
  margin-top: 1.6rem;

  &.alternative {
    max-width: 53rem;
    text-align: center;
  }
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}
