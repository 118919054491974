@import '~theme/variables';

.desktopContainer {
  border: 1px solid $color-neutral-04;
  border-radius: $border-radius-03;
  padding: 2.4rem;
  background-color: $color-neutral-06;
  display: none;

  @include breakpoint(lg) {
    display: block;
  }
}

.title {
  margin-bottom: 2.4rem;
}

.divider {
  border: none;
  border-top: 1px solid $color-neutral-04;
  margin: 2.4rem 0;
}

.mobileContainer {
  border-bottom: 1px solid $color-neutral-04;
  padding: 1.6rem 0;
  background-color: $color-neutral-06;
  display: block;

  @include breakpoint(lg) {
    display: none;
  }
}

.mobileTrigger {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.collapsibleContent {
  overflow: hidden;
}
.collapsibleContent[data-state='open'] {
  animation: slideDown 160ms ease-out;
}
.collapsibleContent[data-state='closed'] {
  animation: slideUp 160ms ease-out;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}

.mobileContent {
  padding-top: 1.6rem;
}
