@import '~theme/variables';

.container {
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
}

.value {
  font-size: $body-font-size;
  color: $color-neutral-02;
}

.button {
  display: inline-flex;
  align-items: center;

  & svg {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.datepickerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.8rem;
  font-weight: 600;

  & button {
    padding: 0.4rem;
    border-radius: $border-radius-01;
  }
}

.overlay {
  position: fixed;
  background: $color-neutral-01;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(0.4rem);
  cursor: pointer;
  transition: 0.3s ease-in-out;
  opacity: 0.6;

  @include breakpoint('sm') {
    display: none;
  }
}

.alert {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -0.8rem;
  background: $color-neutral-01;
  color: $color-neutral-06;
  text-align: center;
  padding: 0.8rem 0;
  transform: translateY(100%);
  border-radius: $border-radius-03;
  font-weight: 500;
}
