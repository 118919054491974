@import '~theme/variables';

.button {
  text-decoration: none;
  align-items: center;

  svg {
    fill: $color-primary;
  }
}

.loading {
  width: 1.33rem;
}

.light {
  color: $color-neutral-06;
}
