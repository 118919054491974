@import '~theme/variables';

.header {
  margin-top: 3.6rem;
  margin-bottom: 2.4rem;
}
.title {
  margin-bottom: 1.6rem;

  @include breakpoint(md) {
    margin-bottom: 2.4rem;
  }
}
