@import '~theme/variables';

.row {
  margin-bottom: $space-m;
  row-gap: $space-m;

  @include breakpoint(sm, max) {
    margin-bottom: $space-s;
    row-gap: $space-s;
  }
}
