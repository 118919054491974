@import '~theme/variables';

.trigger {
  width: 100%;

  &[data-state='open'] .indicator {
    transform: rotate(90deg);

    path:first-of-type {
      opacity: 0;
    }
  }
}

.indicator {
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
}

.indicator,
.indicator path {
  fill: currentColor;
  flex-shrink: 0;
  transition: transform 160ms;
}

.content {
  overflow: hidden;

  &[data-state='open'] {
    animation: slideDown 160ms ease-out;
  }

  &[data-state='closed'] {
    animation: slideUp 160ms ease-out;
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
