@import '~theme/variables';

.list {
  margin: 0;
  padding: 0;
}

.item {
  display: flex;
  align-items: center;
  padding: 0.4rem 0;
  gap: 0.8rem;
  line-height: $small-line-height;

  & svg {
    fill: $color-primary;
  }
}
