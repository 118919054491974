@import '~theme/variables';

.smHidden {
  @include breakpoint(sm, max) {
    display: none;
  }
}

.smVisible {
  @include breakpoint(sm) {
    display: none;
  }
}

.compareSection {
  display: flex;
  margin-top: 1.2rem;
  justify-content: center;

  @include breakpoint(sm) {
    justify-content: flex-end;
  }
}

.compareButton {
  color: $color-neutral-02;

  &:hover {
    color: $color-primary;
  }
}
