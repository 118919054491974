@import '~theme/variables';

.section {
  margin: $space-l 0;
  @include breakpoint(sm, max) {
    margin: 0;
    margin-bottom: $space-m;
  }
}

.colNoPadding {
  padding: 0;
}

.row {
  margin-bottom: $space-m;
  row-gap: $space-m;

  @include breakpoint(sm, max) {
    margin-bottom: $space-s;
    row-gap: $space-s;
  }
}
