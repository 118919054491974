@import '~theme/variables';

.row {
  display: flex;
  justify-content: space-between;
  display: flex;
  gap: 1.6rem;
  color: $color-neutral-01;
}

.content {
  display: flex;
  justify-content: space-between;
  gap: 1.6rem;
  flex: 1;
}

.expandableRow {
  flex-direction: column;
  gap: 1.6rem;
}

.label {
  font-weight: 500;
}

.value {
  text-align: right;
}
