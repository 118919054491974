@import '~theme/variables';

.container {
  position: relative;
}

.card {
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: left;
  border-radius: $border-radius-03;
  border: 2px solid $color-primary;
  overflow: hidden;
  background: $color-neutral-06;
}

.tabs {
  display: flex;
  justify-content: stretch;
}

.tab {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-neutral-05;

  &[data-checked='true'] {
    background: $color-neutral-06;
  }
}

.tag {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
}

.cardFooter {
  background: $color-neutral-06;
}
