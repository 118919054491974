@import '~theme/variables';

.textInputWrapper {
  display: flex;
  flex-direction: column;
  gap: $space-xs;

  .inputWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: $space-s;
    .errorIndicator {
      fill: $color-error;
      transform: translateX(-28px);
    }

    .successIndicator {
      fill: $color-success-02;
      transform: translateX(-28px);
    }
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    gap: $space-xs;
  }

  .title {
    font-size: 1.6rem;
  }

  label {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &.required {
    .title {
      &::after {
        content: ' *';
        font-size: 1.6rem;
      }
    }
  }

  input {
    width: 2.4rem;
    height: 2.4rem;
    border: 2px solid $color-neutral-04;
    border-radius: 50%;
    cursor: pointer;

    &:checked {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1rem;
        height: 1rem;
        background-color: $color-neutral-01;
        border-radius: 50%;
      }
    }
  }
}

.error {
  .title {
    color: $color-error;
  }
  input {
    outline: none;
    border-color: $color-error;
  }
}
