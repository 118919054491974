@import '~theme/variables';

.list {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  @include breakpoint('sm') {
    gap: 2.4rem;
  }
}

.horizontal {
  flex-direction: row;
}
