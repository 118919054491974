@import '~theme/variables';

.container {
  position: relative;
  width: 100%;
}

.select {
  background-color: $color-neutral-05;
  border-color: transparent;
  border-radius: $border-radius-03;
  padding: 1rem 4rem 1rem 1.2rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: $color-neutral-01;
  width: 100%;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;

  @include breakpoint(sm) {
    padding: 1rem 4rem 1rem 2.4rem;
  }

  &::-ms-expand {
    display: none;
  }
}

.caret {
  position: absolute;
  right: 1.2rem;
  top: 50%;
  transform: translateY(-50%);
  height: 2.4rem;
  pointer-events: none;
}

.error {
  border-color: $color-error;
}
