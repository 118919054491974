@import '~theme/variables';

.table-head th {
  position: relative;
  padding: $space-s 0;
  vertical-align: center;
  text-align: center;

  &:first-of-type {
    width: 10rem;
  }

  &:last-of-type {
    border-left: 0.1rem solid $color-neutral-04;
  }

  @media only screen and (min-width: 481px) {
    &:first-of-type {
      width: 22rem;
    }
  }
}

.badgeWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(50%);
  z-index: 1;
}
