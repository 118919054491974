@import '~theme/variables';

.list {
  display: grid;
  gap: 1.6rem 2.4rem;
  color: $color-neutral-02;
  margin-top: 1.2rem;
  margin-bottom: 3.2rem;

  @include breakpoint(sm) {
    grid-template-columns: 1fr 1fr;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
