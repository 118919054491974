.body,
.footer {
  margin-top: 1.6rem;
}

.wrapper {
  display: flex;
}

.input-container {
  flex-grow: 1;
  margin-right: 0.8rem;
}
