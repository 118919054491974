@import '~theme/variables';

.textInputWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  flex: 1;

  .labelWrapper {
    font-size: 1.6rem;
    display: flex;
  }

  label {
    display: block;
    font-size: 1.6rem;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.required {
    label {
      max-width: calc(100% - 1ch);
      margin-right: 2px;
    }
  }

  input {
    border: 2px solid $color-neutral-04;
    font-size: 1.6rem;
    padding: 12px 16px;
    border-radius: $space-xs;
    width: 100%;
    line-height: 2rem;
    font-family: $font-family-primary;

    &:focus,
    &:visited,
    &:active {
      outline-color: $color-primary;
    }
  }
  &.error {
    label {
      color: $color-error;
    }
    input {
      outline: none;
      border-color: $color-error;
    }
    .requiredMark {
      color: $color-error;
    }
  }
}

.inputWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.indicatorWrapper {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  padding-right: 1.2rem;
  pointer-events: none;

  .errorIndicator {
    fill: $color-error;
  }

  .successIndicator {
    fill: $color-success;
  }
}
