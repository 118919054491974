@import '~theme/variables';

.container {
  position: relative;
  min-height: 100vh;
  padding-bottom: 4.8rem;
}

.wrapper {
  margin-top: 6.4rem;
  background-image: url(('/static/images/shared/checkout/background.svg'));
  background-repeat: no-repeat;
  background-position: top -50px right;
  background-size: 16rem;

  @include breakpoint(md) {
    background-size: auto;
    background-position: top right;
  }
}

.sidebar {
  display: none;

  @include breakpoint(lg) {
    display: flex;
    position: sticky;
    top: 9.6rem;
    z-index: $z-index-normal;
    flex-direction: column;
    gap: 2.4rem;
  }
}
