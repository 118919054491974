@import '~theme/variables';

.fieldset {
  border: 0;
  margin: 3.2rem 0;

  &:first-of-type {
    margin-top: 0;
  }
}

.legend {
  font-size: $h4-font-size;
  font-weight: 500;
  margin-bottom: 1.6rem;

  @include breakpoint('sm') {
    font-size: $h4-font-size-large;
    margin-bottom: 2.4rem;
  }
}
