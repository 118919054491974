@import "~theme/variables";

.empty-item {
  border-radius: $border-radius-03;
  background: $color-neutral-06;
  margin-top: 0.8rem;
  padding: 1.6rem;
  height: 9.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-text {
  font-style: italic;
}
