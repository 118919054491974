@import '~theme/variables';

.dateOfBirth {
  display: flex;
  flex-direction: column;
}

.wrapper {
  margin-top: $space-xs;
  display: flex;
  justify-content: space-between;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}
