@import "~theme/variables";

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 5.4rem;
  height: 5.4rem;
  font-size: 2rem;
  border-radius: $border-radius-03;
  background: $color-primary;
  color: $color-neutral-01;
  fill: $color-neutral-01;
  transition: $transition-01;
}

.button:hover {
  background: $color-neutral-01;
  color: $color-neutral-06;
  fill: $color-neutral-06;
}
