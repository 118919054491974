@import '~theme/variables';

.container {
  display: flex;
  align-items: flex-start;
  gap: $space-xs;
  color: $color-neutral-02;

  @include breakpoint(md, max) {
    flex-direction: column;
    align-items: center;
  }
}

.list {
  display: flex;
  gap: $space-xxs;

  li {
    position: relative;
    width: 1.6rem;
  }

  svg {
    animation-duration: 200ms;
    animation-fill-mode: both;
  }

  .placeholder {
    fill: $color-neutral-04;
    stroke: $color-neutral-04;
  }

  .filled {
    left: 0;
    position: absolute;
    opacity: 1;
    fill: $color-primary;
    stroke: $color-primary;
  }

  &.animated {
    .filled {
      opacity: 0;
    }

    &.isVisible .placeholder {
      animation-name: fadeOut;
    }

    &.isVisible .filled {
      animation-name: bounceIn;
    }

    // Stagger animation
    @for $i from 1 to 6 {
      li:nth-child(#{$i}) svg {
        animation-delay: $i * 100ms;
      }
    }
  }
}

.isPanelTooltip {
  margin: 0 $space-l;
}

.tooltipTrigger {
  color: unset;
  text-decoration: underline;
  transition: color $transition-01;

  &:hover,
  &:focus {
    color: $color-primary;
  }
}

.copyWrapper {
  span,
  button {
    @include breakpoint(sm, max) {
      font-size: 1.4rem;
    }
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    scale: 0;
  }

  90% {
    opacity: 1;
    scale: 1.15;
  }

  100% {
    scale: 1;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
