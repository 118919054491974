@import '~theme/variables';

.section {
  @include breakpoint(sm) {
    margin-top: $space-xxl;
  }
}

.headerHolder {
  display: flex;

  @include breakpoint(sm, max) {
    margin-top: $space-m;
    flex-direction: column-reverse;
  }

  @include breakpoint(md) {
    align-items: flex-start;
  }
}

@include breakpoint(sm) {
  .titles {
    display: grid;
    align-items: flex-end;
    container-type: inline-size;
  }

  .titles > * {
    grid-row: 1;
    grid-column: 1;
  }
}

.titles {
  position: relative;
}

.stretchedTitle {
  z-index: -1;
  position: absolute;
  display: flex;
  gap: $space-xl;
  font-size: 27cqi;
  white-space: nowrap;
  opacity: 0.1;
  pointer-events: none;
  left: -100%;

  @include breakpoint(md, max) {
    gap: $space-m;
  }

  @include breakpoint(sm, max) {
    display: none;
  }
}

.image {
  z-index: 1;
}

.image img {
  overflow: hidden;
  border-radius: $border-radius-03;
}

.contentHolder {
  padding-top: $space-xs;

  @include breakpoint(sm) {
    padding-top: $space-s;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: $space-l;

  @include breakpoint(sm, max) {
    gap: $space-m;
    order: 1;
  }
}

.quote {
  p:before,
  p:after {
    content: '"';
  }
}

.attributes {
  display: flex;
  flex-wrap: wrap;
  gap: $space-xs;
}

.info {
  ul {
    list-style: initial;
    margin: revert;
    padding: revert;
  }
  p:not(:first-child):not(:last-child) {
    margin: revert;
  }
}
