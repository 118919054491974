@import '~theme/variables';

.container {
  height: 6.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @include breakpoint(sm, max) {
    height: 4.4rem;
  }

  .menu {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(sm, max) {
      flex-direction: row-reverse;
    }
  }

  .club-name-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .view-club-link {
    flex-direction: row-reverse;

    svg {
      fill: $color-primary;
    }

    @include breakpoint(sm, max) {
      text-decoration: underline;
      color: $color-neutral-02;
    }
  }
}

.underline {
  text-decoration: underline;
}

.change-club {
  margin-left: 0.8rem;
  cursor: pointer;
}

.club-dropdown {
  position: absolute;
  top: 11.5rem;
  @include breakpoint(sm, max) {
    left: 15px;
  }
}

.hide-on-mobile {
  @include breakpoint(sm, max) {
    display: none;
  }
}
