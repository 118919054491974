@import '~theme/variables';

.container {
  background-color: $color-neutral-06;
  padding: 1.6rem;
  border-radius: $border-radius-03;
  display: flex;
  justify-content: center;
  width: 28rem;
  margin: 0 auto;
  margin-top: 4.8rem;

  .icon {
    margin-right: 0.6rem;
    font-size: 2.4rem;
  }

  p {
    margin-top: 0.2rem;
  }

  .contact {
    color: $color-neutral-01;
    margin-left: 0.6rem;
    text-decoration: underline;

    span {
      font-weight: $body-font-weight;
      font-size: $small-font-size;
    }
  }
}

.alternative {
  background-color: $color-neutral-05;
  border: 0.1rem solid $color-neutral-02;
  margin: 2.4rem 0;
  display: inline-flex;
  justify-content: left;
  width: unset;

  .icon {
    fill: $color-neutral-02;
  }

  p {
    color: $color-neutral-02;
    font-size: 1.4rem;

    .contact {
      color: $color-neutral-02;
    }
  }
}

.padding-small {
  padding: 0.8rem 1.6rem;
  margin-top: 0.8rem;

  svg {
    width: 1.6rem;
  }
}
