@import '~theme/variables';

.container {
  display: flex;
  justify-content: center;
}

.rating {
  color: $color-neutral-01;
}
