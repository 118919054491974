@import '~theme/variables';

.formHeaderImages {
  position: absolute;
  right: 0;
  top: -6rem;
  width: 27%;

  @include breakpoint(sm, max) {
    right: 10%;
  }
}

.imageContent {
  position: relative;
  img {
    border: 0.1rem solid $color-neutral-06;
    border-radius: $border-radius-03;
    position: absolute;
    box-shadow: $box-shadow-01;
    border: 0.1rem solid $color-neutral-06;
    width: 10rem;
    height: 13rem;
  }
}

$column-1: 25.3rem;
$column-2: 13.8rem;
$column-3: 2.3rem;
$column-4: -7.6rem;

.image1 {
  position: absolute;
  top: 5rem;
  right: $column-1;
  margin-right: $space-s;
}

.image2 {
  top: 9rem;
  right: $column-2;
  margin-right: $space-s;
}

.image3 {
  top: 4rem;
  margin-right: $space-s;
  right: $column-3;
}

.image4 {
  top: 17rem;
  right: $column-3;
  margin-right: $space-s;
  margin-top: $space-m;
  @include breakpoint(sm, max) {
    display: none;
  }
}

.image5 {
  top: -2rem;
  right: $column-4;
  margin-top: $space-s;
}

.image6 {
  top: 12.6rem;
  right: $column-4;
  margin-top: $space-m;
}

.image7 {
  top: 28rem;
  right: $column-4;
  margin-top: $space-m;

  @include breakpoint(sm, max) {
    display: none;
  }
}
