@import '~theme/variables';

.row {
  margin-bottom: $space-m;
  row-gap: $space-xxs;
}

.houseNumber {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}
