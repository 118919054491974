@import '~theme/variables';

.container {
  text-align: right;
  display: flex;
  flex-direction: column;
}

.values {
  display: flex;
  align-items: center;
  gap: 0.4rem;

  @include breakpoint('sm') {
    flex-direction: column;
    align-items: flex-end;
    gap: 0;
  }
}

.originalPrice {
  color: $color-neutral-02;
  text-decoration: line-through;
  line-height: 1;
}

.description {
  line-height: 1;
}
