@import "~theme/variables";

.logo {
  display: none;

  @include breakpoint(sm) {
    position: absolute;
    width: 8.7rem;
    z-index: $z-index-dialog;
    height: 9.5rem;
    mix-blend-mode: exclusion;
    right: 1.8rem;
    top: 20rem;
    height: 11.9rem;
    display: block;
  }
}

.content {
  position: relative;
  height: auto;
  padding: 1.6rem;
  overflow: auto;

  @include breakpoint(sm) {
    padding: 3.2rem;
  }

  .image {
    margin-bottom: 2.4rem;

    @include breakpoint(sm) {
      font-size: 2.8rem;
      margin-bottom: 3.6rem;
    }
  }

  .title {
    margin-bottom: 1.6rem;
    font-size: 2.6rem;

    span {
      color: $color-popup;
    }
  }

  .details {
    margin-bottom: 1.6rem;
  }
}

.buttonsContainer {
  display: flex;
  padding: 1.6rem;

  @include breakpoint(sm) {
    padding: 3.2rem;
    margin-top: 2.4rem;
  }

  :nth-child(n+2) {
    margin-left: 1.6rem;
  }
}
